import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { ContractContext, contractStatuses } from '../contexts/ContractContext';
import { BlackOrWhiteButton, CountdownOrMint, ConnectButton, Transaction } from './';


export default function MintForm({color, variant}) { 
  const [mintCount, setMintCount] = useState(1);
  const { contractStatus, currentAccount, mintPrivate, mintPublic, publicSupply, totalSupply } = useContext(ContractContext);

  function handleChange (e) {
    setMintCount(e.target.value);
  }

  async function onClick (e) {
    let minted = '';
    if (contractStatuses[contractStatus] == "Public" ) {
      minted = await mintPublic(mintCount);
    } else if (contractStatuses[contractStatus] == "AllowListOnly") {
      minted = await mintPrivate(mintCount);
    } else {
      console.log('paused')
    }

    console.log(minted);
  }

  if (!currentAccount) {
    return <ConnectButton color={color || "white"} />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: variant == 'column' ? 'center' : 'flex-start',
      }}
    >
      { (contractStatuses[contractStatus] != "Paused") &&
        (
        <Typography
          variant="body2"
          sx={{
            mb: 2
          }}
        >
          {totalSupply} / {publicSupply} minted.
          {totalSupply == publicSupply ? ' Buy on ' : ''}
          {totalSupply == publicSupply &&
            (
              <a 
                href={process.env.NEXT_PUBLIC_OPENSEA_URL} 
                target="_blank" 
                rel="noreferrer"
                style={{
                  textDecoration: 'underline'
                }}
              > Opensea.</a>
            )
          }
        </Typography>
        )
      }

      <FormControl 
        fullWidth
        sx={{
          display: 'flex',
          flexDirection: variant == 'column' ? 'column' : 'row',
          width: variant == 'column' ? '50%' : '100%',
          justifyContent: variant == 'column' ? 'center' : 'flex-start',
        }}
      >
        <InputLabel 
          id="mint-count-label"
          sx={{
            fontFamily: 'Inter, sans-serif'
          }}
        >
          Mint count
        </InputLabel>
        <Select
          labelId="mint-count-label"
          id="mint-count"
          value={mintCount}
          label="Mint count"
          onChange={handleChange}
          sx={{
            width: variant == 'column' ? '100%' : '30%',
            borderRadius: '0'
          }}
        >
          { 
            [...Array(10)].map((_, i) =>{
              return <MenuItem key={i} sx={{fontFamily: 'Inter, sans-serif'}} value={i+1}>{i+1}</MenuItem>
            })
          }
        </Select>
        <BlackOrWhiteButton
          color={color || "white"}
          onClick={onClick}
          sx={{
            width: variant == 'column' ? '100%' : '50%',
            ml: variant == 'column' ? 0 : 2,
            mt: variant == 'column' ? 2 : 0
          }}
        >
          { contractStatuses[contractStatus] == "Paused" && 
            <AccessTimeIcon 
              sx={{
                mr: 0.5
              }}
            />
          }
          <CountdownOrMint />
        </BlackOrWhiteButton>
      </FormControl>   
      <Transaction />
    </Box>
  )
}
