import { Button, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

const colorsAndHoverColor = {
  'black': {
    color: 'black',
    hover: 'rgba(0,0,0,0.9)',
    textColor: 'white',
  },
  'white': {
    color: 'white',
    hover: 'rgba(255,255,255,0.9)',
    textColor: 'black',
  }
}

export default function BlackOrWhiteButton ({children, color, sx, onClick}) {
  return (
    <Button
      variant='contained'
      onClick={onClick}
      sx={[
        {
          backgroundColor: colorsAndHoverColor[color].color,
          borderRadius: 0,
          boxShadow: 'none',
          border: '1px solid black',
          width: {
            xs: 180,
            md: 300,
          },
          ...sx
        },
        {
          '&:hover': {
            backgroundColor: colorsAndHoverColor[color].hover,
          }
        },
      ]}
    >
      <Typography
        variant="overline"
        sx={{
          fontFamily: 'Inter, sans-serif',
          fontSize: '1.1666rem',
          lineHeight: '2.6666rem',
          textTransform: 'lowercase',
          color: colorsAndHoverColor[color].textColor,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </Typography>
    </Button>
  )
}

Button.propTypes = {
  // only colors supported for this project are black and white
  color: PropTypes.oneOf(['black', 'white'])
}