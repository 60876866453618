import { Box, Button, Container, Typography } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import DiscordIcon from './DiscordIcon';

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        width: '100%',
        justifyContent: {
          xs: 'center',
          sm: 'space-between',
        },
        alignItems: 'center',
        p: 3,
        mt: 0,
      }}
    >
      <Typography
        variant='body2'
        sx={{
          color: 'black',
          fontFamily: 'Helvetica, sans-serif'
        }}
        >
        All material &copy; 2022 Barry Sutton, 96 Studio
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          href="https://twitter.com/barrylsutton"
          target="_blank"
        >
          <TwitterIcon 
            sx={{
              color: 'black',
              fontSize: {
                xs: '30px',
                md: '30px',
              },
              m: {
                xs: 0,
                md: 2
              },
              mr: {
                xs: 2,
              }
            }} 
          />
        </Button>
        <Button
          href="https://discord.com/invite/BabqFeB5QV"
          target="_blank"
        >
          <DiscordIcon />
        </Button>
      </Box>
    </Container>
  )
}
