import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import { ContractContext } from '../contexts/ContractContext';
import { ConnectButton, Container, MintedPieces, MintForm, Navbar } from './';


export default function Hero() {
  const { currentAccount } = useContext(ContractContext);

  return (
    <Container>
      <Navbar />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              mb: 4,
              display: 'flex',
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
              justifyContent: 'space-between'
            }}
          >
            <Box>
              <Typography
                variant="h1"
                sx={{
                  textTransform: 'uppercase',
                  mb: 2,
                  fontSize: {
                    xs: '4rem',
                    sm: '8rem'
                  },
                  lineHeight: {
                    xs: '4rem',
                    sm: '8rem'
                  }
                }}
              >
                journey to the <br/> unexpected
              </Typography>
              <Typography
                variant="subtitle"
                sx={{
                  fontSize: '2rem',
                }}
              >
                365 works by barry sutton
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: {
                  xs: '100%',
                  sm: '42%',
                },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  mb: {
                    xs: 0,
                    sm: 2.6666,
                  }
                }}
              >
                <b>Presale Mint Date:</b> April 6th, 2022, 3:00pm EDT.
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mb: {
                    xs: 0,
                    sm: 2.6666,
                  }
                }}
              >
                <b>Public Mint Date:</b> April 7th, 2022, 3:00pm EDT.
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mb: {
                    xs: 2,
                    sm: 4,
                  }
                }}
              >
                <b>Mint Price:</b> 0.18 ETH.
              </Typography>
              { !currentAccount ? <ConnectButton color="white" /> : <MintForm /> }
            </Box>
          </Box>
          {/* <MintedPieces /> */}
          <img
            src="/hero-background.jpg"
            style={{
              width: '100%',
            }}
          /> 
        </Box>
      </Box>
    </Container>
  )
}
