import { Alert, Snackbar } from '@mui/material';
import { useContext } from 'react';
import { ContractContext } from "../contexts/ContractContext";

const Notification = () => {
  const { errorMessage, setErrorMessage } = useContext(ContractContext);

  const message = {
    type: 'error',
    text: errorMessage ? 
      errorMessage.length > 100 ? 
        errorMessage.slice(0, 100) + '...'
        : errorMessage
      : ''
  }

  const handleClose = () => {
    setErrorMessage();
  }
  
  return (
    <Snackbar
      open={errorMessage && !!errorMessage.length}
      onClose={handleClose}
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
    >
      <Alert severity={message.type}>
        {message.text}
      </Alert>
    </Snackbar>
  )
}

export default Notification;