import { Box, Button, Drawer } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import { MintForm } from './';

export default function DrawerContainer ({open}) {
  return (
    <Drawer
      variant="temporary"
      open={open}
      anchor='bottom'
    >
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flexDirection: 'column',
          pb: 12,
        }}
      >
        <Button
          sx={{
            mb: {
              xs: 1,
              sm: 3
            }
          }}
          href="https://twitter.com/barrylsutton"
          target="_blank"
        >
          <TwitterIcon sx={{
            color: 'black',
            width: 30,
            height: 30,
          }} />
        </Button>
        <Button
          sx={{
            mb: 3,
          }}
          href="https://discord.com/invite/BabqFeB5QV"
          target="_blank"
        >
          <img 
          src='/discord.webp' 
          style={{
            width: '30px',
          }}
        /> 
        </Button>
        <MintForm color="black" variant="column" />
      </Box>
    </Drawer>
  )
}