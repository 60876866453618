import { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Artist, Circle, Description, Footer, Hero, Notification, Photogrid, Project, SwitchNetwork, Theme } from '../components';
import { ContractProvider } from '../contexts/ContractContext';
import { Box, Collapse } from '@mui/material';

export default function Home() {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontsLoaded(true);
    });
  }, [fontsLoaded])

  return (
    <ContractProvider>
      <Theme>
        <CssBaseline />
        <SwitchNetwork />
        <Notification />
        <Collapse
          in={fontsLoaded}
        >
          <Hero />
          <Circle />
          <Description />
          <Project />
          <Artist />
          <Photogrid />
          <Footer />
        </Collapse>
      </Theme>
    </ContractProvider>
  )
}
