import { Box, Button, CircularProgress, Collapse, Typography } from "@mui/material";
import { useContext } from "react";
import { ContractContext } from '../contexts/ContractContext';
import { shortenAddress } from '../utils/shortenAddress';

export default function Transaction () {
  const { transactionHash, transactionResult } = useContext(ContractContext);

  if (!transactionHash || !transactionHash.length) return '';

  console.log(transactionResult)

  return (
    <Collapse
      in={!!(transactionHash.length)}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          border: '1px solid #333',
          p: 2,
          pt: 2,
          mb: 6,
          mt: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: {
              xs: 'flex-start',
              sm: 'center'
            },
            justifyContent: 'space-between',
            flexDirection: {
              xs: 'column',
              sm: 'row'
            },
            flexWrap: 'wrap'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <Typography
              variant="body2"
              sx={{
              }}
            >
              <b>Transaction:</b> {shortenAddress(transactionHash)}
            </Typography>
            <Typography
              variant="body2"
            >
              View on <a href={`${process.env.NEXT_PUBLIC_ETHERSCAN_URL}/tx/${transactionHash}`} target="_blank" rel="noreferrer">Etherscan</a>
            </Typography>
          </Box>
          <Button
            variant="outlined"
            sx={{
              borderRadius: 0,
              ml: {
                xs: 0,
                sm: 2,
              },
              mt: {
                xs: 2,
                sm: 0,
              },
            }}
            href={transactionResult ? `${process.env.NEXT_PUBLIC_OPENSEA_URL}` : ''}
            target="_blank"
          >
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              { transactionResult ? '' : <CircularProgress size={20} sx={{mr: 2}} /> }
              { transactionResult ?  "View on OpenSea" :  "In progress "}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Collapse>
  )
}
