import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useContext } from 'react';
import { BlackOrWhiteButton, CountdownOrMint } from './';
import { ContractContext } from '../contexts/ContractContext';

export default function ConnectButton ({color, sx}) {
  const { connectWallet, currentAccount } = useContext(ContractContext);

  return (
    <BlackOrWhiteButton
      sx={sx}
      color={color}
      onClick={connectWallet}
    >
      { currentAccount && (
        <span
          style={{
            display: 'flex',
            marginRight: '0.5rem',
          }}
        >
          <AccessTimeIcon
            height={18}
            width={16}
          />
        </span>
      )}
      { !currentAccount ? 'Connect wallet' : <CountdownOrMint color={color} /> }
    </BlackOrWhiteButton>
  )
}