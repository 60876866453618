
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import keccak256 from "keccak256";
import { MerkleTree } from "merkletreejs";

import { chainIds } from '../utils/constants';
import { isDebug } from '../utils/isDebug';
import contractAbi from './contractAbi.json';
import whitelist from "./whitelist";

export const ContractContext = React.createContext();

if (isDebug()) {
  console.log('DEBUG mode: ', isDebug());
}

const contractAddress = process.env.NEXT_PUBLIC_NYC365_CONTRACT_ADDRESS; 

function getProvider () {
  const { ethereum } = window;
  const provider = new ethers.providers.Web3Provider(ethereum);
  return provider;
}

// fetch NYC365 Contract
const getNYC365Contract = () => {
  const provider = getProvider();
  const signer = provider.getSigner(); //get the signer address
  const contract = new ethers.Contract(contractAddress, contractAbi, signer); //get the contract
  
  return contract;
}

export const contractStatuses = ['Public', 'AllowListOnly', 'Paused'];

export const ContractProvider = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState("");
  const [allowedPrivateMint, setAllowedPrivateMint] = useState(false);
  const [contractStatus, setContractStatus] = useState(2);
  const [totalSupply, setTotalSupply] = useState(0);
  const [publicSupply, setPublicSupply] = useState(0);
  const [mintPrice, setMintPrice] = useState(0);
  const [isMinting, setIsMinting] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [switchNetwork, setSwitchNetwork] = useState(false);
  const [currentChainId, setChainId] = useState();
  const [mintedPieces, setMintedPieces] = useState([]);
  const [transactionHash, setTransactionHash] = useState();
  const [transactionResult, setTransactionResult] = useState();

  const checkCurrentChain = async () => {
    const { chainId } = await getProvider().getNetwork();
    setChainId(chainId);

    if (process.env.NEXT_PUBLIC_DEBUG && isDebug() && chainId && chainId.toString() != chainIds.RINKEBY) {
      setSwitchNetwork(true);
    };
    if (process.env.NEXT_PUBLIC_DEBUG && !isDebug() && chainId && chainId.toString() != chainIds.MAINNET) {
      setSwitchNetwork(true);
    }; 
  }

  const getContractStatus = async () => {
    const { ethereum } = window;

    try {
      if (!ethereum) return alert("Please install MetaMask.");
      const nyc365Contract = await getNYC365Contract();
      const contractStatus = await nyc365Contract.contractStatus();

      setContractStatus(contractStatus);
      return contractStatus;
    } catch (error) {
      console.log('error: ', error);
      setErrorMessage(error.message);
    }
  }

  const getTotalSupply = async () => {
    const { ethereum } = window;

    try {
      if (!ethereum) return alert("Please install MetaMask.");
      const nyc365Contract = await getNYC365Contract();
      const totalSupplyContract = await nyc365Contract.totalSupply();

      setTotalSupply(totalSupplyContract.toNumber());
      return totalSupplyContract;
    } catch (error) {
      console.log('error: ', error);
      setErrorMessage(error.message);
    }
  }

  // actually getting Max supply
  const getPublicSupply = async () => {
    const { ethereum } = window;

    try {
      if (!ethereum) return alert("Please install MetaMask.");
      const nyc365Contract = await getNYC365Contract();
      const publicSupplyContract = await nyc365Contract.maxSupply();

      setPublicSupply(publicSupplyContract.toNumber());
      return publicSupplyContract;
    } catch (error) {
      console.log('error: ', error);
      setErrorMessage(error.message);
    }
  }

  const canMintPrivate = async () => {
    const { ethereum } = window;

    try {
      if (!ethereum) return alert("Please install MetaMask.");
      const nyc365Contract = await getNYC365Contract();
      const leaves = whitelist.map(keccak256);
      const merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true });
      const hexProof = merkleTree.getHexProof(keccak256(currentAccount));
      const _canMintPrivate = await nyc365Contract.canMintPrivate(currentAccount, hexProof);
      setAllowedPrivateMint(_canMintPrivate);
      return _canMintPrivate;
    } catch (error) {
      console.log('error: ', error);
      setErrorMessage(error.message);
    }
  }
  
  const getMintPrice = async () => {
    const { ethereum } = window;

    try {
      if (!ethereum) return alert("Please install MetaMask.");
      const nyc365Contract = await getNYC365Contract();
      const mintingPrice = await nyc365Contract.price();
      setMintPrice(mintingPrice);
      return mintingPrice;
    } catch (error) {
      console.log('error: ', error);
      setErrorMessage(error.message);
    }
  } 

  const mintPrivate = async (quantity) => {
    const { ethereum } = window;

    try {
      if (!ethereum) return alert("Please install MetaMask.");
      const nyc365Contract = await getNYC365Contract();
      const leaves = whitelist.map(keccak256);
      const merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true });
      const hexProof = merkleTree.getHexProof(keccak256(currentAccount));
      setTransactionResult(null);
      setIsMinting(true);
      const mintPrivateTransaction = await nyc365Contract.mintPrivate(quantity, hexProof, {value: (mintPrice * quantity).toString() + '000000000'});
      setTransactionHash(mintPrivateTransaction.hash);
      const mintedPrivate = await mintPrivateTransaction.wait();
      setTransactionResult(mintedPrivate);
      setIsMinting(false);
      await getTotalSupply();
      await getMintedPieces();
      return mintedPrivate;
    } catch (error) {
      console.log('error: ', error);
      setIsMinting(false);
      setErrorMessage(error.message);
    }
  }

  const mintPublic = async (quantity) => {
    const { ethereum } = window;

    try {
      if (!ethereum) return alert("Please install MetaMask.");
      const nyc365Contract = await getNYC365Contract();
      setTransactionResult(null);
      setIsMinting(true);
      const mintingPublic = await nyc365Contract.mintPublic(quantity, {value: (mintPrice * quantity).toString() + '000000000'});
      setTransactionHash(mintingPublic.hash);
      const minted = await mintingPublic.wait();
      setTransactionResult(minted);
      setIsMinting(false);
      await getTotalSupply();
      await getMintedPieces();
      return minted;
    } catch (error) {
      console.log('error: ', error);
      setIsMinting(false);
      setErrorMessage(error.message);
    }
  }

  const getMintedPieces = async () => {
    try {
      const results = await fetch(`/api/minted/${currentAccount}`);
      const result = await results.json();

      Promise.all(result.map(async (piece) => {
        const metadataResult = await fetch(`/api/metadata/${piece.token_id}`);
        const metadata = await metadataResult.json();
        return metadata
      })).then((pieces) => {
        setMintedPieces(pieces);
      })

    } catch (error) {
      console.log('error: ', error);
      setErrorMessage(error.message);
    }
  }

  // set debug mode
  useEffect(() => {
    window.debug = process.env.NEXT_PUBLIC_DEBUG;
    checkCurrentChain();
  }, [currentChainId]);

  const connectWallet = async () => {  
    const { ethereum } = window;

    try {
      if (!ethereum) {
        window.location.replace('https://metamask.app.link/dapp/mint.nyc365.xyz/');
      }

      const accounts = await ethereum.request({ method: 'eth_requestAccounts'});

      setCurrentAccount(accounts[0]);
    } catch (error) {
      console.log('error: ', error);
      setErrorMessage(error.message);
    }
  }

  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window; 
    
    try {
      // if (!ethereum) return alert('Please install a wallet provider (like Metamask).');
      
      const accounts = await ethereum.request({ method: "eth_accounts" });
      
      if (accounts.length) {
        setCurrentAccount(accounts[0]);
        
      } else {
        // setErrorMessage("No accounts found");
      }
    } catch (error) {
      console.log('error: ', error);
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    checkIfWalletIsConnected();
    checkCurrentChain();
    if (currentAccount) {
      canMintPrivate();
      getContractStatus();
      getTotalSupply();
      getPublicSupply();
      getMintPrice();
      getMintedPieces();
    }
  }, [currentAccount])

  return (
    <ContractContext.Provider value={{
      allowedPrivateMint, 
      connectWallet,
      contractStatus,
      currentAccount,
      errorMessage,
      isMinting,
      mintPublic,
      mintPrivate,
      mintedPieces,
      publicSupply,
      setErrorMessage,
      setSwitchNetwork,
      switchNetwork,
      totalSupply,
      transactionHash,
      transactionResult,
    }}>
      {children}
    </ContractContext.Provider>
  )
}