const whitelist = [
"0x765718a8e4a5562be34b122af259ce5a71372e12",
"0x8d870b49ca41fb392b1888d6bad32af40dc90f06",
"0xa2803a616c0f887cbee75514bbe17fe1733417fa",
"0x8145d0f8e95a316acdbc52312705ca132a15dbae",
"0x859b3a83992d4fd00e052f7ae291e644b0b6c2a2",
"0xa269fab02cf89f43e63db80a3a5178c7eb3a6d65",
"0x7a926e039b30b9ca5a0499e6a8b1f7fe2c30aef8",
"0x27c2f7512900a5853c7d82f8ecb116b8a62a0037",
"0xa459de4abeacce99b08142df6766f75474ab79ea",
"0x35be279b2ef0004afcf4ce83c0b5beb605be72f1",
"0x3cd378c9b1cb5f147ebf1b2c2564118946ae4ba1",
"0x448ac87fc473a4d9802084289dcbf0c5faf9eadd",
"0x4c48d00997c6d23d6b47a9ce8caa6027d0068e42",
"0xc01acaa6d2fb9ea774437a1d725d2e5a283033cc",
"0x87d81c0e6d409c55c8cfdd2ef77a5d6769692bf1",
"0x27a00e2b2665a9dea2edb83dad4cc33bdbb58074",
"0x60d727314be577304d1c43cfd19c3f5d0dc7f876",
"0x54c20cd386128d36a96ce0582218a1710056652e",
"0x38b2f1be5a757fca373d4084edef8547f6fb11ce",
"0xe3e85c7f6ed91ba213002e8efa5ceab570a9e6a5",
"0x616602e1b90e5cbfc7b626df6e88cb86d41512f1",
"0x15a33852846b4762d71a62928aa7c64d8aac8544",
"0x7bdd4e39f3a09fa84e0868db8b8039d36c79b894",
"0x73c64d0a6979978ba62b67205e8d735819c50bac",
"0xcaca7d69b7163b7bb8bf5f691fdd9793360f691f",
"0xe61252912c463f9a6e083751948c918d37243b30",
"0x1dad49620fb7d9719aef54a61aeda532a8604d26",
"0x76e5f9010ceffae60582fcfb2099552368459ea2",
"0x9e121698d68cf4c2b34202ccee11ea62769a8bc7",
"0x37ff23267db26e232ec9e13d874eff9cbc07342a",
"0x7782c1c31f0eed9ebf466d78beec0cb0cd2b51af",
"0x49b496a7729ffed1502b0e2d3573c7e87a60cf9c",
"0x21bb3eb127b7b96f39e3e99b0fb7f4051c4994a8",
"0x86a435699d17d73f7dfee044eeaa1e2fc5d8d9b2",
"0x461573a5133de8a704d88241e93b95951c0f55e2",
"0xdc2d5126c0147863e97e2a5c91553ac45ffa5374",
"0xbbc1196f4e228fd7595aed976aca38f867b9d7fd",
"0x941463d0eb90033cf551a9f0efa89ff596e7e78e",
"0x1a008d20550a5cdaa31392895eb92b5e150fe10a",
"0xba819ed81f8035bb9c8dab06d2c6d1b083ffbf8a",
"0x9a885e52fb4f449ab077bfa64d87ea0b55b7ec3f",
"0x833505cc4e4a4842acf87635765f655e498d54c1",
"0x20448152963ca8adfb572534c749dde27b1abedb",
"0x64601adaa0048cb5d3c6ea450d1eaf6e4b23056d",
"0xd61ba5beb4711ba795b2a2e7722d649b3f797bfd",
"0x8d8b48401ea73ba93bad94bbe2524b4754597201",
"0x3bf856111223340b1b0d84265c6836776630ab1a",
"0x888e44eccad0dbc2afa7a7f4b1a65e14fc9cd561",
"0x702f1e9410f9ee6442ed5dfd8992776579243ec8",
"0xf896e426615e44a2610f4c3d5343b63b557e11e2",
"0x6b6ae848f555f70944bc99d736fe29fcaecf8b23",
"0x8d555d28abdd29b2f86bc9d8ba8f266831407125",
"0x80c939f8a66c59b37330f93f1002541fd4e51aa2",
"0x682acbcf554b44f5f266548cf2c39b355e88b452",
"0x5ce3c40d3db0fdd9c81a65fd01507f92ff93c9a1",
"0xa711047a7d6fb5b11bf74d29068f190724cb1add",
"0xef0570110fccdbbd3ad3c0093db23d14c19231ec",
"0x4306a9f32ce8c597b34ee8d580df06cec369cf33",
"0xb5beebbfb568be3d5d7afc7c35cac5bc517a1fa4",
"0xdde40d8b412f8ef7b881386f2ea88770e8f5b9f4",
"0x1c4bffe778cba14d253c471900bd8deec0deb428",
"0x4e066dd371fd4e64871fe1d982f998758aef5a8f",
"0x092c0068bb79f07e07dd730f03976e39c21dea5f",
"0x78010d7ca4dded981c28cfa18063f3f7501b1653",
"0xaf77e6ce8fef4b096e909ebe6c475cb991c27675",
"0xbcd2e843fe41da351cde58bceb40d69242b229db",
"0x007313ccb39af27676fd55bce43c6eae1bf83fbb",
"0xce435038720f5db23cc8627f825382dfe85a7e06",
"0xe4575c894b13c99c8f3e9fc0dcdd220131aabc0b",
"0x6db5e720a947c7cc2f3fcad4cf5058402fc456c6",
"0x3ea4ea76efbc4da7207635b3705654ba8d674a62",
"0xc442e5fe08e6b2d36ebb97ad78eb22867bacdd5d",
"0xab35becb4f0cfc408300131e802b9c51e868c5d8",
"0x8fc11ab9a5f72a4f0d12199a72f474933bd71064",
"0x631cd42eb1063d1fe2b49a78744f3094702edbab",
"0x0279b26fa484e6587514e3db9e2b02dfea968ad8",
"0x77771eb9efc4ffd5e8dc2eb952fbba20aee5975c",
"0x6b6566341c9905a401e62952bd670aad7f56c18e",
"0xfe899c851c20f84e1a4d53600bcab433eefcb966",
"0xf174b63a150abaa38d9e5a01cdae9791ea3025cd",
"0xb18e3c41faf4139b89b4ebf1f5ef645a3ad0ec7f",
"0x344a4c67ee8ae84b89bcde443f982c07ae5b1358",
"0xc9cfb1eec514bcad0b50d0e5b44a9bb0216b0ea4",
"0xc839de0fed241607d50aa4107ae582443b906e4c",
"0x3c7cb3b81e4ae4122dbc8261d57770f7af750514",
"0x40da2891c23748d1e521c563bb7c71d822f17d3e",
"0x9ba6e0ea2fdf65e8aee29d6ec60a4d515294cf4f",
"0xbe1ce9584bb326322963ae244175470232756f39",
"0xd3cbf8645d8f28f6758504b0cf2ab4206c5dd7cc",
"0xb2eb4de0b3aa095528dcd60c74671c6f2dcb1253",
"0x8002a176b6e5adc952745521e9080664d6536160",
"0xdfbe7a3bc6a188068405387a56414ba0be2c9dd4",
"0x974447055f110c4271d48a1c7837dfdf9d570d19",
"0xfa8ebf2a9efe34701af9c9f96dc32ce3925d913d",
"0xce6920f5620df958778cb52d119ecf78b0963337",
"0x1a256faeef1a4e30ae141a3f77ed578a0d02f7fb",
"0x0e1a8ecbaed25f34fc93e224db436842f9f1bef8",
"0x06358d728027f3b4904cf55c3a7a3645c591e3fa",
"0x85b65bd430f4b473d33eb97b0e5a20aabab46cfc",
"0x620910b020fd12e7b1ff8c0d52eda66bf31213d4",
"0xad8798d1c0d85a94dfb8c0b033ecaaf38029ba94",
"0x7516241835aaa87ff731224d60a0108768b89c6b",
"0x9553803357f9441ee426e701a1bd8031f814fcf0",
"0x8b212d14a77c7eaa5491c3829ee60b9972b8ed65",
"0x4ec8e85b5cef9df1ac16a9d953ceef453ffa28e1",
"0x96091bd3c98c6cc592b5c390e77dd0a8e3be2fdd",
"0x7cdbb8e309ce7c701ed200be123f16c6f7c9e525",
"0x164c3df08784171e0c4b61b7682263371f1eeb74",
"0x67aa898c4050c92a93cec7aef7390f7d80794a39",
"0x4ed95e1a632ba57ab02f02c7cf948f13ac0cedc2",
"0xc24d8bbdc53da814d36dfa23a92e3356691b546d",
"0x3fcddb0dd912e6f68927b448546cce99f22a3e31",
"0x5b2094bc95238cd5a861ea3bc8f2e3c0c4678ad6",
"0x8379ac05ef71f982d497f392dc1a14ee859efd95",
"0x3ce99cf4c97d44f3a91e2964ca38cd499fc990eb",
"0xbc6e70cb9b89851e6cff7ce198a774549f4c0f0c",
"0x3f138407a8893f20fc47b4ef0a9c972c19084a57",
"0xbe863eadd096fe478d3589d6879d15794d729764",
"0x0b01f1310e7224dafed24c3b62d53cec37d9faf8",
"0x23787626349cc170bfc512e5b5e3d3f44e00d02d",
"0xa8c14d9fe2cbdf56e610f8f4647c2776c3505526",
"0xa381d21bc2bc9e349dc4332854788d1036bbd107",
"0x34474cefa85ff114bf89cd17b5d54e331bdddab1",
"0x8029d8d22610e457360e7bdfb388e138a7730da5",
"0xa4939a893c7aefe9629d7525be3db799a9e1045b",
"0xc8733e51f30d9cf5f296eb8d8a4e7d643a94a73e",
"0x36abc45216ee411581da092b9caa98ac460afc45",
"0x35b64947f786c8b756b35fd25ef2b9917acc25d3",
"0x9f3bce237ea107ffad3aa7852f8dd847e6b82a5d",
"0xf89c94f43b36719046b55e2ae60bacbfc0db1c6a",
"0x40f465f1ba4c2aba91c0c896cb92bbe4c7e545df",
"0x57a879266c02bd29d11c956156e9a524de4483d7",
"0x3e09005c7b9fc14b4f035260aa4a38b44566dd62",
"0x5ad3b640c760ca369321682a0ffbf866c07b2b5a",
"0x1f8a12ad2f144193b12543ba7fd0410351142858",
"0xbac3346e78f18485575b94ad0b1132017eccb62f",
"0x0ca43432e83defb4cb2856c0eccadc8507db8142",
"0x5bd3bf853b9970d93da64d7628919997c1a06a6c",
"0x4f64c6b8333f74890b0ba0af4d480d8ecce01e17",
"0x2705045ef16d185a84aef570cddde535a0a95d1f",
"0xab0e881e98afa20b42b16f5f078b68b10004f862",
"0xf50bdeafecca3a679d26ae4c2dec953e032a6fda",
"0x18a72ce31b61b98cda91dabbabaa04d61d9a0e6d",
"0x519ae0c11f21f217ad4a7e4eb09e3bbe0e8ffd9d",
"0x788f93c6165b1ae7a1297527d0037160a32c1252",
"0x72fef1abced43b44e2c838f68b785fc7028fb2c0",
"0x354ddeba429d9e43ec1438af5319b2158ad36afd",
"0xcb3408bad3192777e561b362e8467c213231ef9f",
"0xd10f2265827308957d31100f171403b4c574060d",
"0x79d16570483afc8c749216bfc0b8abf759ead7c4",
"0xc23bcc018f438854b98a3d4aef677a989855e707",
"0x7decf7a31168778f311c57b9a948abaa7321001e",
"0x8ee376de530fb9a734df676e7e4342b48355f483",
"0x70ddda43d4f616263b1db0ec672cbd8795db13b1",
"0x5Ed592557b6A29908B7d50305cA01904a4529cF4",
"0x359b7628f98f3b73e9bdcb2d8476932342fc5aea",
"0x68bca5a8bdebe05fb8a6648c7316b4eb7e19a064",
"0x1fd582af476d465c197de0176f26c4a5f3f82c29",
"0x40da2891c23748d1e521c563bb7c71d822f17d3e",
"0xc9cfb1eec514bcad0b50d0e5b44a9bb0216b0ea4",
"0xe4575c894b13c99c8f3e9fc0dcdd220131aabc0b",
"0x6db5e720a947c7cc2f3fcad4cf5058402fc456c6",
"0x760e7B42c920c2a0FeB58DDD610c14A6Bdd2Ebea",
"0x1fC30Deba76628e93197F438E107BcEf51842D6f",
"0xC939a204c4b1D5C90586Aa3dd8656fe6974fD7AE",
];

export default whitelist;
