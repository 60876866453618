import { Box, Typography, useMediaQuery } from '@mui/material';
import MintForm from './MintForm';
import { Container } from './';

export default function Description() {
  const largeScreen = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <Container
      sx={{
        mb: {
          xs: 6,
          sm: 16,
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          justifyContent: 'space-between',
        }}
        >
        <Box
          sx={{
            minWidth: {
              xs: '50%',
            },
            mr: {
              xs: 0,
              sm: 12
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: {
                xs: '4rem',
                sm: '6rem'
              },
              lineHeight: {
                xs: '4rem',
                sm: '6rem'
              },
              width: '100%',
            }}
          >
            365 images from the streets of NYC above and below. 
          </Typography>
          <Typography
            variant="body1"
            sx={{
              my: 2.666,
            }}
          >
            More than a documentary record of New York City, nyc365 is a fantastical view of the beauty, contrast, grit, glow and rhythm of the city, full of surrealism, abstraction, impressionism, cubism and realism- all the major art movements seem to be represented by one photographer in one grand collection of photography of this magnificent city.
          </Typography>
          <MintForm color="white" />
        </Box>
        <Box
          sx={{
            display: {
              xs: 'flex',
              sm: 'none',
            },
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            mt: 6,
          }}
        >
          <img
            src='/description-image.png'
            style={{
              height: '100%',
              maxWidth: '100%',
              aspectRatio: '3/4',
              width: '100%'
            }}
          />
        </Box>
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'flex',
            },
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%'
          }}
        >
          <img
            src='/description-image.png'
            style={{
              marginRight: '-15rem',
              aspectRatio: '3/4'
            }}
          />
        </Box>
      </Box>
    </Container>
  )
}