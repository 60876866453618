import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import MintForm from './MintForm';

export default function Project() {
  // const largeScreen = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <Container>
      <Box
        sx={{
          borderTop: '1px solid black',
          display: 'flex',
          flexDirection: 'column',
          py: '5.333rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: '5.3333rem',
            flexDirection: {
              xs: 'column',
              md: 'row'
            },
            
          }}
        >
          <Box
            sx={{
              width: {
                xs: '100%',
                md: '50%'
              },
              minWidth: '60%',
            }}
          >
            <Typography
              variant="h2"
            >
              Project Details.
            </Typography>
            <Typography
              variant="h5"
              sx={{
                my: 2.6666,
                fontSize: '2rem',
              }}
            >
              Artist: Barry Sutton
            </Typography>
            <Typography
              variant="h5"
              sx={{
                my: 2.6666,
                fontSize: '2rem',
              }}
            >
              365 Works, 1/1
            </Typography>
            <Typography
              variant="h5"
              sx={{
                my: 2.6666,
                textTransform: 'none',
                fontSize: '2rem',
              }}
            >
              Randomized Mint, 0.18 ETH
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                mb: 2.6666
              }}
            >
              <b>Categories.</b> Year, Streets, Subway, Protests, Pandemic, Snowstorms, Sunsets, Bikes, Beach, Shadows, Torn, Landmarks, Camera/Lens Info.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mb: 2.6666
              }}
            >
              <b>Rare content.</b> 5 slow-motion, reverse-motion and time-lapse videos, 2 ultra-rare collages and 1 unique diptych.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mb: 2.6666
              }}
            >
              <b>Free airdrop. </b> Holders of 96&#176; in the Shade, Paris in the Summer or The COVID Diaries will receive a random piece from the collection on April 6.
            </Typography>
            <MintForm color="white" />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start'
          }}
        >
          <img
            src='/project-image.jpeg'
            style={{
              width: '100%'
            }}
          />
        </Box>
      </Box>
    </Container>
  )
}