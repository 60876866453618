import { Alert, Button, Snackbar } from '@mui/material';
import { useContext } from 'react';
import { ContractContext } from "../contexts/ContractContext";
import { isDebug } from '../utils/isDebug';

const SwitchNetwork = () => {
  const { switchNetwork, setSwitchNetwork } = useContext(ContractContext);

  const handleClose = () => {
    window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{
        chainId: isDebug() ? '0x4' : '0x1'
      }]
    });

    setSwitchNetwork(false);
  }

  return (
    <Snackbar
    open={switchNetwork}
    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
    >
      <Alert 
        severity='info'
        action={
          <Button onClick={handleClose}>
            Switch Network
          </Button>
        }>
        {isDebug() ? 'Switch to Rinkeby for debugging' : 'Detected test network, switch to Ethereum Mainnet to migrate'}
      </Alert>
    </Snackbar>
  )
}

export default SwitchNetwork;