import { useState } from 'react';
import { Squash as Hamburger } from 'hamburger-react';
import { Box, Button, Typography } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import DiscordIcon from './DiscordIcon';
import { ConnectButton, Drawer } from './';

export default function Navbar() {
  const [isDrawerOpen, setDrawerOpen]=useState(false);

  return (
    <>
      <Drawer 
        open={isDrawerOpen}
      />
      <Box 
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 4,
          mb: 8,
        }}
        >
        <Typography
          variant='body1'
          sx={{
            color: isDrawerOpen ? 'black' : 'black',
            fontFamily: 'Anton, sans-serif',
            fontSize: '3rem',
            lineHeight: '4rem',
            zIndex: 1201,
          }}
          >
          NYC365
        </Typography>
        <Box
          sx={{
            pl: 1,
            ml: 1,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          
          <Button
            sx={{
              display: 'flex',
              minWidth: {
                xs: '30px',
                sm: '60px',
              }
            }}
            href="https://twitter.com/barrylsutton"
            target="_blank"
          >
            <TwitterIcon sx={{
              color: 'black',
              height: 30,
              width: 30,
            }} />
          </Button>
          <Button
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: {
                xs: '30px',
                sm: '60px',
              }
            }}
            href="https://discord.com/invite/BabqFeB5QV"
            target="_blank"
          >
            <DiscordIcon />
          </Button>
          <Button
            sx={{
              display: {
                xs: 'inline-block',
                sm: 'none',
              },
              minWidth: '20px',
              p: 0,
              zIndex: 1201,
            }}
          >
            <Hamburger 
              toggled={isDrawerOpen} 
              toggle={setDrawerOpen} 
              size={25} 
              color="black"
              zIndex={100}
            />
          </Button>
        </Box>
    </Box>
    </>
  )
}
