import { Box, Container } from "@mui/material";

export default function WrappedContainer ({sx, children, disableGutters}) {
  return (
    <Container 
      sx={sx}
      maxWidth="xl"
    >
      <Box
        px={{
          xs: 2,
          sm: "38px"
        }}
      >
        {children}
      </Box>
    </Container>
  )
}