import { Box, CircularProgress } from '@mui/material';
import { useContext } from 'react';
import Countdown from 'react-countdown';
import { ContractContext } from '../contexts/ContractContext';

export default function CountdownOrMint ({color}) {
  const { allowedPrivateMint, isMinting } = useContext(ContractContext);

  const publicMintDate = Date.parse("07 Apr 2022 15:00:00 EDT");
  const privateMintDate = Date.parse("06 Apr 2022 15:00:00 EDT");

  if (Date.now() < privateMintDate) {
    return <Countdown date={privateMintDate} /> 
  } else if (!allowedPrivateMint && Date.now() < publicMintDate) {
    return <Countdown date={publicMintDate} /> 
  } else {
    return (
      isMinting ? <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress size={20} sx={{mr: 2}} /> 
        ...minting
      </Box>
       : "Mint"
    )
  }
}