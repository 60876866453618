import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '@fontsource/anton/400.css';
import '@fontsource/bodoni-moda/400.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1312
    },
  },
  palette: {
    primary: {
      main: '#333333'
    },
  },
  typography: {
    fontSize: '12px',
    h1: {
      fontSize: '8rem',
      lineHeight: '8rem',
      textTransform: 'uppercase',
      fontFamily: [
        'Anton',
        'sans-serif'
      ].join(','),
    },
    h2: {
      fontSize: '6rem',
      lineHeight: '6rem',
      textTransform: 'uppercase',
      fontFamily: [
        'Anton',
        'sans-serif'
      ].join(','),
    },
    h3: {
      textTransform: 'uppercase',
      fontFamily: [
        'Anton',
        'sans-serif'
      ].join(','),
    },
    h4: {
      fontSize: '1.6rem',
      textTransform: 'uppercase',
      fontFamily: [
        'Anton',
        'sans-serif'
      ].join(','),
    },
    h5: {
      textTransform: 'uppercase',
      fontFamily: [
        'Anton',
        'sans-serif'
      ].join(','),
    },
    h6: {
      textTransform: 'uppercase',
      fontFamily: [
        'Anton',
        'sans-serif'
      ].join(','),
    },
    subtitle: {
      fontFamily: [
        'Inter',
        'sans-serfi'
      ].join(', '),
    },
    body1: {
      fontFamily: [
        'Inter',
        'sans-serif'
      ].join(','),
      fontSize: '1.5rem',
      lineHeight: '2.6666rem',
    },
    body2: {
      fontFamily: [
        'Inter',
        'sans-serif'
      ].join(','),
      fontSize: '1.16666rem',
      lineHeight: '2rem',
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: 'red',
        }
      }
    }
  }
})

export default function Theme ({children}) {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}