import { Box } from "@mui/material";

export default function DiscordIcon(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <img 
        src='/icons/discord.svg' 
        style={{
          width: '30px',
          marginLeft: '0'
        }}
      />
    </Box>
  )
}