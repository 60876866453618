import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import Image from 'next/image';

export default function Artist() {
  const largeScreen = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pt: '5.3333rem',
      }}
    >
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'row'
            },
            justifyContent: 'space-between',
            mb: 6,
          }}
          >
            {/* image responsive block */}
            {/* MOBILE */}
            <Box
              sx={{
                display: {
                  xs: 'flex',
                  sm: 'none',
                },
                position: 'relative',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                maxWidth: '100%',
              }}
            >
              <img
                src='/artist-bg.png'
                alt='Photo of steamy sidewalk'
                style={{
                  maxWidth: '100%',
                  aspectRatio: '3000/2249',
                  width: '100%'
                }}
              />
            </Box>
            {/* DESKTOP */}
            <Box
              sx={{
                display: {
                  xs: 'none',
                  sm: 'flex',
                },
                position: 'relative',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '50%',
                mr: 12
              }}
            >
              <img
                src='/artist-bg.png'
                alt='Photo of steamy sidewalk'
                style={{
                  aspectRatio: '3000/2249',
                  width: '100%'
                }}
              />
            </Box>
            {/* image responsive block */}
          <Box
            sx={{
              
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              width: {
                xs: '100%',
                sm: '40%'
              }
            }}
          >
            <Typography
              variant="h2"
              sx={{
                mb: 4,
                mt: {
                  xs: 6,
                  sm: 0,
                }
              }}
            >
              The Artist.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: 3
              }}
            >
              Barry Sutton jumped into the NFT space with a splash. His genesis collection 96° in the Shade has achieved a sort of cult status among collectors and has been a fixture on the top 20 all-time list of NFT photography projects. A founding member of the Raw DAO and Untitled DAO, Barry has just launched his own incubator, 96 Studio, bringing artists with significant bodies of work to the blockchain.
            </Typography>  
          </Box>
          
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            mt: {
              xs: 6,
              sm: '16rem'
            },
            flexDirection: {
              xs: 'column',
              sm: 'row',
            }
          }}  
        >
          <Typography
            variant="h2"
            sx={{

              mb: 4,
              fontSize: '4rem',
              minWidth: {
                xs: '100%',
                sm: '34.6666rem'
              }
            }}
          >
            The Photographs.
          </Typography>
          <Box>
            <Typography
              variant="body1"
              sx={{
                mb: 3
              }}
            >
              A man in a black cap stares at a larger than life portrait that appears to be him as an older man; a building emerges from the cobblestones while the pedestrians march upside down below the surface like a scene from a Murakami novel; six hands grasp a subway pole; two bodiless feet emerge from a cloud of steam while one enters the unknown; four striped legs join the stripes of the crosswalk; suggestions of a conspiracy scrawled on a construction wall, two young boys in Harlem immaculately dressed marching to school.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: 3
              }}
            >
              nyc365 is a song with many verses, the story of a city of contrasting elements, whose movements are like an orchestra of more than 8 million, that in its randomness, performs with such purpose, rhythm and harmony, must be guided by a divine force.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}