import { Box, Container, ImageListItem } from "@mui/material";
import { MintForm } from "./";

const imageList = [
  '1.png',
  '2.jpeg',
  '3.png',   
  '4.jpeg',  
  '5.jpeg',
  '6.jpeg',
  '7.jpeg',
  '8.jpeg',
  '9.jpeg',
  '10.jpeg',
  '11.png',  
  '12.jpeg',
  '13.jpeg',
  '14.jpeg',
  '15.jpeg',
  '16.jpeg',
  '18.jpeg',
  '17.jpeg',
  '19.jpeg',
  '20.png',
  '22.jpeg', 
  '21.png',  
  '23.jpeg',
  '24.jpeg',       
];

export default function Photogrid() {
  return (
    <Box
      sx={{
        p: {
          xs: 1,
          md: 6
        },
        pt: {
          xs: 3,
          md: 6
        }
      }}
    >
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: "repeat(2, 1fr)",
              sm: "repeat(4, 1fr)",
              md: "repeat(6, 1fr)",
            },
            mb: 6
          }}
        >
          {imageList.map((pathname, index) => (
            <ImageListItem key={pathname}>
              <img
                src={`/grid/${pathname}`}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </Box>
        <MintForm 
          color="black" 
          variant="column"
        />
      </Container>
    </Box>
  )
}