import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function Circle () {
  return (
    <Box
      sx={{
        display: 'flex',
        px: 2,
        my: 16,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          border: '1px solid #999',
          borderRadius: '50%',
          height: {
            xs: '320px',
            sm: '416px',
          },
          width: {
            xs: '320px',
            sm: '416px',
          },
          whiteSpace: 'nowrap',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontFamily: 'Bodoni-moni, serif',
            fontSize: {
              xs: '3.2rem',
              sm: '5.3333rem'
            },
            lineHeight: {
              xs: '3.2rem',
              sm: '5.3333rem'
            },
            textAlign: 'center',
            textTransform: 'inherit',
          }}
        >
          &#8220;A sweeping portrait<br/>
           of the city like you&apos;ve<br/>
           never seen it.&#8221;
        </Typography>
      </Box>
    </Box>
  )
}